import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import List from "../../components/list"
import ListItem from "../../components/list-item"
import ListItemLink from "../../components/list-item-link"

export const projectsQuery = graphql`
  query ProjectsQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "project" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
          }
        }
      }
    }
  }
`

interface ProjectsProps {
  allMarkdownRemark: {
    edges: {
      node: {
        id: string
        fields: {
          slug: string
        }
        frontmatter: {
          title: string
          templateKey: string
        }
      }
    }[]
  }
}
const Projects: React.FC = () => (
  <StaticQuery
    query={projectsQuery}
    render={(props: ProjectsProps) => {
      const { allMarkdownRemark } = props
      const { edges } = allMarkdownRemark
      return (
        <Layout>
          <h1>Projects</h1>
          <List>
            {edges.map(edge => {
              const {
                node: {
                  id,
                  fields: { slug },
                  frontmatter: { title },
                },
              } = edge
              return (
                <ListItem key={`projects-project-${id}`}>
                  <ListItemLink to={slug}>{title}</ListItemLink>
                </ListItem>
              )
            })}
          </List>
        </Layout>
      )
    }}
  />
)

export default Projects
